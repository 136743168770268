import Slider from 'react-slick';
import styles from './Slider.module.scss';
import { useState, useRef } from 'react';
import Icon1 from '../../../../public/halogensvgs/Icon1'
import Icon2 from '../../../../public/halogensvgs/Icon2'
import Icon3 from '../../../../public/halogensvgs/Icon3'
import Icon4 from '../../../../public/halogensvgs/Icon4'
import React from 'react';

const slideItems = [
  {
    text: "<p><span>Reach</span> - With a broad data network we’re able to deliver on your need to engage with new audiences for you, and deliver qualified leads in guaranteed volumes.</p>",
    icon: <Icon1 />,
    icontext: 'REACH'
  }, {
    text: "<p><span>Rates</span> - We offer competitive pricing approaches, working on a fixed cost per lead model, with volume discounts available, allowing our customers to benefit from margin gains. </p>",
    icon: <Icon2 />,
    icontext: 'RATES'
  }, {
    text: "<p><span>Results</span> - We’ll always deliver what we commit to, never leaving our clients without the promised result, allowing them to continue the nurture journey in appropriate timeframes.</p>",
    icon: <Icon3 />,
    icontext: 'RESULTS'
  }, {
    text: "<p><span>Reliability</span> - We’re often referred to as an extension of our client’s team, and this is how we strive to work. We’ll always forecast accurately, deliver ahead of schedule and offer options to help you and your clients.</p>",
    icon: <Icon4 className={styles.thumbsUp} />,
    icontext: 'RELIABILITY'
  },
]

const SliderIcon: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);

  const settings = {
    dots: true,
    fade: true,
    arrows: false,
    infinite: true,
    autoplay: false,
    speed: 10,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_: any, newIndex: number) => setCurrentSlide(newIndex),
    afterChange: (current: number) => setCurrentSlide(current)
  };

  const goToSlide = (index: number) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  return (
    <section className={styles.slider}>
      <div className='container'>
        <div className='row g-5 align-items-center justify-content-between'>
          <div className='col-12 col-xl-6 col-lg-6'>
            <h3 className='md'>Halogen provides fast activation of content syndication, providing accurate opted-in data direct to your CRM with a focus on 4 key areas:</h3>
            <div className={styles.iconWrapper}>
              {slideItems.map((slide, index) => (
                <div key={index} className={styles.iconText}>
                  <div

                    className={`${styles.svgWrapper} ${currentSlide === index ? styles.activeIcon : ''
                      }`}
                    onMouseOver={() => goToSlide(index)}
                  >

                    {slide.icon}
                  </div>
                  <span className={styles.textIcon}>{slide.icontext}</span>
                </div>


              ))}
            </div>
          </div>
          <div className={`col-12 col-xl-6 col-lg-6 mt-5 mt-lg-0`}>
            <div className={styles.sliderWrapper}>
              <Slider ref={sliderRef} {...settings}>
                {slideItems.map((slide, i) => (
                  <div key={i} className={styles.slideWrapper}>
                    {slide.text && <div dangerouslySetInnerHTML={{ __html: slide.text }} />}
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SliderIcon;
