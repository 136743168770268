import { useRef, useState } from "react";
import styles from './Form.module.scss'

const Form = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!formRef.current) return;

    fetch('https://script.google.com/macros/s/AKfycbzGQtCTmggS-0SIilMIpe1Lf9UtoZRga2T_Z7XeGFtflfGNqpX52pkftu1QkFvcb44/exec', {
      method: 'POST',
      body: new FormData(formRef.current),
    })
      .then(res => {
        console.log("success");
        setSubmitted(true);
        formRef.current?.reset();
      })
      .catch(err => console.log(err));
  };

  return (
    <div className={styles.form} id="contact-form">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-12 m-auto">
            <h3>Ready to unlock new audiences? If you’re interested in hearing more about Halogen, get in touch — we’d love to hear from you.</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7 col-12 m-auto">
            {!submitted ? (
              <form onSubmit={handleSubmit} ref={formRef} name="halogen-form">
                <div className={styles.name}>
                  <input name="First Name" type="text" placeholder="First name" required />
                  <input name="Last Name" type="text" placeholder="Last name" required />
                </div>
                <input name="Email" type="email" placeholder="Email" required />
                <input name="Phone" type="text" placeholder="Phone number" required />
                <input name="Reason" type="text" placeholder="How did you find out about us?" />
                <input name="Message" type="text" placeholder="Your message" />
                <button className={styles.submitBtn} type="submit">Contact us</button>
              </form>
            ) : (
              <div className='pt-5'>
                <h3>Thank you! We&apos;ll be in touch soon.</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
