interface Icon3Props {
  className?: string;
}

const Icon3: React.FC<Icon3Props> = ({ className }) => {
  return (
    <svg width="72" height="69" viewBox="0 0 72 69" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M36.2807 59.7612C46.4671 59.7612 56.6582 59.7519 66.8446 59.7658C69.8455 59.7658 71.8679 62.1989 71.2947 65.0383C70.9173 66.9082 69.305 68.2979 67.2919 68.4826C67.0217 68.5057 66.7514 68.5011 66.4811 68.5011C46.2574 68.5011 26.0384 68.5011 5.81468 68.5011C3.30303 68.5011 1.49501 67.2176 1.02902 65.1399C0.395286 62.319 2.40834 59.7889 5.36268 59.7704C10.358 59.7427 15.358 59.7612 20.3534 59.7612C25.6609 59.7612 30.9685 59.7612 36.2761 59.7612H36.2807Z" fill="#148893" />
      <path d="M41.677 26.0387C41.677 19.2102 41.6677 12.3863 41.677 5.55784C41.677 3.12009 42.9165 1.4903 45.0834 0.973195C47.5065 0.396074 49.9343 1.96123 50.3723 4.39437C50.4608 4.88377 50.4888 5.38702 50.4888 5.88103C50.4934 19.2656 50.4888 32.6502 50.4981 46.0348C50.4981 48.0847 49.8411 49.7284 47.87 50.6287C44.9063 51.9815 41.7003 49.8761 41.6817 46.515C41.6491 39.6911 41.6724 32.8626 41.6724 26.0341L41.677 26.0387Z" fill="#148893" />
      <path d="M30.6287 32.5671C30.6287 37.2487 30.666 41.9303 30.6147 46.6119C30.5774 49.8992 27.3714 51.963 24.4451 50.6379C22.5765 49.793 21.8123 48.2556 21.8123 46.2795C21.8123 41.9442 21.8123 37.6089 21.8123 33.2689C21.8123 28.3934 21.789 23.5225 21.8216 18.647C21.8449 15.2997 24.6594 13.1759 27.651 14.1731C29.501 14.7918 30.61 16.3754 30.6194 18.5177C30.638 23.1993 30.624 27.8809 30.6194 32.5625L30.6287 32.5671Z" fill="#148893" />
      <path d="M61.4947 37.3503C61.4947 34.3585 61.4854 31.3713 61.4947 28.3795C61.5087 25.6601 63.3307 23.7764 65.9076 23.781C68.4146 23.7856 70.2972 25.6694 70.3112 28.2964C70.3391 34.3539 70.3391 40.4113 70.3112 46.4688C70.3018 49.1282 68.354 51.0581 65.8191 51.0257C63.3354 50.9934 61.5087 49.0681 61.4947 46.4365C61.4808 43.4077 61.4947 40.379 61.4947 37.3503Z" fill="#148893" />
      <path d="M10.815 40.199C10.815 42.3089 10.8336 44.4189 10.815 46.5289C10.7917 49.0774 8.95102 50.9842 6.51392 51.0304C4.02091 51.0766 2.03115 49.179 2.0032 46.6073C1.96126 42.272 1.9566 37.9413 2.0032 33.606C2.03115 31.0943 4.01159 29.1875 6.43004 29.2014C8.86248 29.2152 10.7777 31.1359 10.8103 33.6429C10.8383 35.8267 10.815 38.0152 10.815 40.199Z" fill="#148893" />
    </svg>


  )
}
export default Icon3