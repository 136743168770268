
import dynamic from 'next/dynamic';
import styles from './Hero.module.scss'
import { useState } from 'react';
const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

interface HeaderProps {
  bgImage?: string;
  title: string;
  videoUrl?: string;
}

const Header: React.FC<HeaderProps> = ({ bgImage, title, videoUrl }) => {
  const [videoReady, setVideoReady] = useState(false);
  return (
    <section className={styles.hero} style={{ backgroundImage: `url(${bgImage})` }}>
      {videoUrl && <div className={styles.video}>
        <ReactPlayer
          className='player'
          url={videoUrl}
          playing={true}
          width='100%'
          height='100%'
          muted
          controls={false}
          onReady={() => setVideoReady(true)}
          loop
          playsinline={true}
        />
      </div>}
      <div className={styles.overlay} />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-9" dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      </div>
    </section>
  )
}

export default Header;