import { useEffect, useRef, useState } from 'react';
import styles from './ImageText.module.scss'
import Lottie, { AnimationDirection, AnimationItem } from 'lottie-web';
import animation from 'public/lottie/test.json';
import { useIsomorphicLayoutEffect } from '@/componentsWP/ScrollPlayback/ScrollPlayback';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import ReactPlayer from 'react-player';

import dynamic from 'next/dynamic';

gsap.registerPlugin(ScrollTrigger);

interface ImageTextProps {
  image?: string;
  text?: string;
  flipOrder?: string;
  flipOrderMobile?: string;
  bgColor?: string;
  textColor?: string;
  verticalAlign?: string;
  paddedX?: string;
  lottie?: string;
  blurred?: boolean;
  colWidthRight?: string;
  colWidthLeft?: string;
  videoUrl?: string;
}

const DynamicPlayer = dynamic(() => import('react-player'), { ssr: false });

const ImageText: React.FC<ImageTextProps> = ({ image, text, bgColor, textColor, flipOrder, verticalAlign, paddedX, lottie, blurred, colWidthRight = '6', colWidthLeft = '6', videoUrl }) => {
  const playerRef = useRef<ReactPlayer | null>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const animationRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  useIsomorphicLayoutEffect(() => {
    if (!blurred || !imgRef.current) return;

    const ctx = gsap.context(() => {

      gsap.fromTo(imgRef.current, { filter: 'blur(30px)' }, { filter: 'blur(0px)', duration: 1, scrollTrigger: { trigger: containerRef.current, start: 'center 80%', end: 'center 20%', toggleActions: 'play reverse play reverse' } })
    })
    return () => {
      ctx.revert();
    };
  }, [containerRef.current, imgRef.current, blurred])

  useIsomorphicLayoutEffect(() => {
    let lineAnim: AnimationItem | null = null;
    if (!lottie) return;

    const ctx = gsap.context(() => {
      if (!animationRef.current || !containerRef.current) return;

      lineAnim = Lottie.loadAnimation({
        renderer: 'svg',
        container: animationRef.current,
        path: lottie,
        autoplay: false,
        loop: false,
      });
      lineAnim.pause();


      const playAnimations = (dir: AnimationDirection) => {
        if (lineAnim) {
          lineAnim.setDirection(dir);
          lineAnim.play();
        }
      };
      ScrollTrigger.create({
        trigger: containerRef.current,
        start: 'center 80%',
        end: 'center 20%',
        onEnter: () => {
          playAnimations(1);
        },
        onEnterBack: () => {
          playAnimations(1);
        },
        onLeave: () => {
          playAnimations(-1);
        },
        onLeaveBack: () => {
          playAnimations(-1);
        },
      });
    });
    return () => {
      ctx.revert();
      if (lineAnim) {
        lineAnim.destroy();
      }
    };
  }, []);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (playerRef.current && containerRef.current) {
      const trigger = containerRef.current;

      const onEnterHandler = () => {
        if (!isVideoPlaying) {
          setIsVideoPlaying(true);
        }
      };

      const onLeaveHandler = () => {
        if (isVideoPlaying) {
          setIsVideoPlaying(false);
        }
      };

      ScrollTrigger.create({
        trigger,
        start: 'top 15%',
        end: 'bottom 100%',
        onEnter: onEnterHandler,
        onLeave: onLeaveHandler,
        onEnterBack: onEnterHandler,
        onLeaveBack: onLeaveHandler,
      });
    }
  }, [isVideoPlaying]);

  return (
    <section ref={containerRef} className={styles.imageText} style={{ backgroundColor: bgColor }}>
      <div className="container">
        <div className={`row g-5 justify-content-between align-items-${verticalAlign}`}>
          <div className={`col-12 ${flipOrder} col-lg-${colWidthRight} mt-xl-5 mt-0 py-lg-0 py-5`}>
            {image && <div className={styles.imgWrapper}>
              <img ref={imgRef} className='img-fluid imggg' src={image} alt="" />
            </div>}
            {lottie && <div ref={animationRef} />}
            {videoUrl && <div className={styles.video} >
              <DynamicPlayer
                className='player'
                ref={playerRef}
                url={videoUrl}
                playing={isVideoPlaying}
                width='100%'
                height='100%'
                muted
                controls={false}
                playsinline={true}
              />
            </div>}
          </div>
          <div className={`col-12  col-lg-${colWidthLeft} py-4 ${paddedX}`}>
            {text && <div style={{ color: textColor }} className={styles.content} dangerouslySetInnerHTML={{ __html: text }} />}
          </div>
        </div>
      </div>
    </section>
  )
}
export default ImageText;