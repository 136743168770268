
import Slider from 'react-slick';
import styles from './Carousel.module.scss'

interface Slide {
  text: string;
  number?: string;
}
interface CarouselProps {
  mainText?: string;
  mainTitle?: string;
  bgImage?: string;
  bgColor?: string;
  bgContent?: string;
  textColor?: string;
  alignText?: any;
  mobBorderadius?: string;
  padded?: string;
  className?: string;
  sliderType?: 'bgSlider' | 'greenSlider';
  slides?: Slide[];
  overlay?: boolean;
  autoplay?: boolean;
}
const Carousel: React.FC<CarouselProps> = ({ mainText, mainTitle, slides, bgImage, bgColor, textColor, bgContent, alignText, mobBorderadius, padded, className, sliderType, overlay, autoplay }) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: autoplay,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

  }
  const sliderOfType = sliderType === 'greenSlider' ? styles.greenSlider : styles.bgSlider;
  return (
    <section className={`text-center ${className} ${sliderOfType} ${padded} ${styles.carousel}`} style={{ backgroundColor: bgColor }}>
      {mainText && <p className='md'> {mainText}</p>}
      {mainTitle && <h4 className='px-3 py-5 h4-39'>{mainTitle}</h4>}
      <div className='container'>
        <div className={styles.sliderWrapper} style={{ backgroundImage: `url(${bgImage})`, backgroundColor: bgContent, borderRadius: mobBorderadius }}>
          {overlay && <div className={styles.overlay} />}
          <Slider {...settings}>
            {slides && slides.map((sl, i) => (
              <div className={styles.slideWrapper} key={i} >
                <div>{sl.number && <p className={styles.circleNum}><span className={styles.num}>{sl.number}</span></p>}</div>
                {sl.text && <div style={{ color: textColor, textAlign: alignText }} dangerouslySetInnerHTML={{ __html: sl.text }} />}
              </div>
            ))}
          </Slider>
        </div>
      </div>

    </section>
  )
}

export default Carousel;