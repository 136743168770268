import Carousel from "@/components/ComponentsHalogen/Carousel/Carousel";
import ImageText from "@/components/ComponentsHalogen/ImageText/ImageText";
import SliderIcon from "@/components/ComponentsHalogen/Slider/SliderIcon";
import Hero from "@/components/ComponentsHalogen/Hero/Hero";
import SEO from "@/components/SEO/SEO";
import Border from "@/components/ComponentsHalogen/Border/Border";
import Form from "@/components/ComponentsHalogen/Form/Form";

const slides = [
  {
    text: "<h2 class='h2-54'>“The client is really inspired by the campaign recommendations for this quarter and has built budget into next year to roll it out across EMEA”</h2>"
  },
  {
    text: "<p class='p-xxxl'>“Our relationship with Halogen has gone from strength to strength. They have become more than just a partner but a trusted part of our demand gen offering. We can trust what they do given their dedication to industry standards compliance and accuracy, but more importantly we can see that they obsess about quality and precision with everything they do with us.”</p>"
  },
  {
    text: "<p class='p-xxxl'>“Projects are delivered on time as agreed and any hiccups are always dealt with quickly and with a client first approach. This is made even easier by the fact that they are a really energised, fun team who are always willing to welcome us to the offices, out for lunch or be on hand to answer any question whatever time. We look forward to working on more projects and working even closer to the team.”</p>"
  },
  {
    text: "<h2 class='h2-54'>“Thanks for delivering these. The titles look spot on!”</h2>"
  },
]





export default function Home(props: any) {
  const protocol = props.host?.includes('localhost') ? 'http://' : 'https://'
  return (
    <div style={{ overflow: "hidden" }}>
      <SEO title='Halogen' og={{ title: "It’s Time To Demand Better Demand Gen ", siteName: "Halogen", image: `${protocol}${props.host}/halogenImg/social_image_homepage.jpg` }} description="We provide fast campaign activation to deliver accurate and compliant opted-in data, helping your organisation fulfil its demand generation programmes." />
      <Hero bgImage="/halogenImg/home-hero.jpg" title="<h1>It’s Time To <br/> Demand Better <br/> Demand Gen </h1>" videoUrl="/halogenVideos/home-hero.mp4" />
      <SliderIcon />
      <ImageText image="/halogenImg/img1.jpeg" flipOrder="order-lg-0 order-1" verticalAlign="center" text="<h4 class='h4-xxxl'>Powerful solutions: Syndicate, engage and convert.</h4> 
      <p class='li-xxl'>Halogen will engage your audience in your chosen format, at every stage of their journey, delivering integrated strategies including email campaigns and tele-prospecting.</p>
      <p class='li-xxl'>With leads optimised not only for volume but for quality, our transparent process ensures your content reaches the right audience and you receive the data into your CRM in a seamless way. </p>
      <p class='li-xxl'>We know that in the lead generation sector, trust can be in short supply, and so we’re committed to being  an antidote.</p>
    " />
      <ImageText flipOrder="order-lg-1 order-0" textColor="#fff" bgColor="#02575C" image="/halogenImg/img2.jpeg" verticalAlign="center" text="<h4 class='h4-xxxl'>Since most of our work is white labelled to enable media publishers like you to hit your goals anonymously, we’ll always be in the background, unseen and unheard, but beavering away to ensure you hit your goals
</h4> 
    <p class='li-xxl'>But we collect no moss. At any given moment, chances are we’re working on something different, challenging our tech team to help us find a solution to a new way of working, so if anything you read on our site is of interest but not 100% clear still, then give us a call and we’ll collaborate to create something that will work for you" />
      <Carousel autoplay={true} overlay mainTitle="What our clients say about us:" bgImage="/halogenImg/bgImage.jpeg" slides={slides} />
      <Form />
    </div>
  )
}

export async function getServerSideProps() {
  return { props: { layout: "halogen" } }
}
